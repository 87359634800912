<template>
  <button
    :id="`auth0-connection-button-${connection.key}`"
    @click="goToLogin"
  >
    <div class="icon-container">
      <CUIcon
        view-box="0 0 24 24"
        width="20"
        height="20"
        :icon-name="connection.label"
      >
        {{ connection.icon }}
      </CUIcon>
    </div>
    <span>Continue with {{ connection.label }}</span>
  </button>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import { useAuth0 } from '@/composables/useAuth0'
import { Auth0Connection } from '@/utils/Auth0Connection'
import { AuthorizationParams, PopupLoginOptions } from '@auth0/auth0-spa-js'

const props = defineProps<{
  connection: Auth0Connection
}>()

const auth0 = useAuth0()

const goToLogin = async () => {
  const authorizationParams: AuthorizationParams = {
    prompt: 'select_account',
    connection: props.connection.connectionName,
  }
  const options: PopupLoginOptions = {
    authorizationParams,
  }
  await auth0.loginWithPopup(options)
}

</script>

<style scoped lang="scss">
@import '@/scss/colors.scss';
@import '@/scss/variables.scss';

button {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid $border-gray;
  border-radius: $border-radius-root;
  transition-delay: 0s;
  transition-duration: 0.15s;
  transition-property: background-color;
  transition-timing-function: ease-in-out;

  &:hover {
    background-color: rgba($border-gray, 0.7);
  }
}

.icon-container {
  height: 50px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
