<template>
  <div :class="{ 'padding-b-34': smAndDown }">
    <v-img
      v-if="smAndUp"
      src="@/assets/images/signIn.png"
      class="margin-b-8"
      max-width="280px"
    />
    <div class="d-flex flex-column align-center" v-if="isProcessingLogin">
      <p class="font-16 font-bold text-primary margin-l-2 margin-b-5">Signing you in...</p>
      <v-progress-circular
        indeterminate
        color="primary"
        :size="24"
        :width="3"
      />
    </div>
    <div v-else>
      <Auth0ConnectionButton
        v-for="(connection, connectionIndex) in SOCIAL_CONNECTIONS"
        :key="`${connection.key}-${connectionIndex}`"
        :connection="connection"
        class="margin-b-2"
      />
      <div class="d-flex align-center margin-t-2">
        <v-divider />
        <span class="margin-l-2 margin-r-2 font-14" style="margin-top: -2px">
          OR
        </span>
        <v-divider />
      </div>
      <div>
        <label>Email</label>
        <v-text-field
          id="quote-builder-authentication-email-text-field"
          ref="emailInput"
          :value="email"
          :rules="[
            (v) => isEmailValid || !v || 'E-mail must be valid',
          ]"
          type="email"
          autocomplete="email"
          hide-details="auto"
          required
          validate-on-blur
          outlined
          @input="email = $event"
        />
      </div>
      <v-btn
        id="quote-builder-authentication-continue-with-email-button"
        small
        color="primary"
        class="margin-t-2 w-full"
        :disabled="!isEmailValid"
        :loading="isLoading"
        @click="continueWithEmail"
      >
        Continue with Email
      </v-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Auth0Connection } from '@/utils/Auth0Connection'
import Auth0ConnectionButton from './Auth0ConnectionButton.vue'
import { validateEmailAddress } from '@/utils/string'
import { computed, ref, watch } from 'vue'
import user from '@/services/user'
import { AuthorizationParams, PopupConfigOptions, PopupLoginOptions } from '@auth0/auth0-spa-js'
import { useAuth0 } from '@/composables/useAuth0'
import { useDisplay } from '@/composables/useDisplay'

const SOCIAL_CONNECTIONS = [
  Auth0Connection.Google,
  Auth0Connection.Apple,
]

const auth0 = useAuth0()
const { smAndUp, smAndDown } = useDisplay()

const emit = defineEmits<{
  (e: 'update:popup'): void
}>()

const email = ref('')
const isLoading = ref(false)

const isEmailValid = computed(() => {
  return validateEmailAddress(email.value)
})

const checkCustomerEmail = async (): Promise<boolean> => {
  let isExistingUser = false

  if (!isEmailValid.value) {
    return isExistingUser
  }

  isLoading.value = true
  try {
    const { data } = await user.doesExistInAuth0(email.value)
    isExistingUser = !!data?.exists
  } catch (error) {
    console.warn('Error checking customer email:', error)
  } finally {
    isLoading.value = false
  }

  return isExistingUser
}

// This function is pulled from the utils inside the @auth0/auth0-spa-js package.
// It is used to open a popup window that is then passed to the loginWithPopup function.
// This is necessary because if the click handler to open the popup is async, the popup will be blocked in Safari.
// By opening a blank popup immediately, before any async operations, we can avoid this issue.
// Auth0 then takes over from there and opens the appropriate login page in the popup.
const openPopup = (url: string): Window | null => {
  const width = 400
  const height = 600
  const left = window.screenX + (window.innerWidth - width) / 2
  const top = window.screenY + (window.innerHeight - height) / 2

  return window.open(
    url,
    'auth0:authorize:popup',
    `left=${left},top=${top},width=${width},height=${height},resizable,scrollbars=yes,status=1`
  )
}

const continueWithEmail = async (): Promise<void> => {
  if (!isEmailValid.value) {
    return
  }

  const popup = openPopup('')

  try {
    const isExistingUser = await checkCustomerEmail()

    const authorizationParams: AuthorizationParams = {
      prompt: 'login',
      screen_hint: isExistingUser ? 'login' : 'signup',
      login_hint: email.value,
      connection: Auth0Connection.Email.connectionName,
    }

    const options: PopupLoginOptions = {
      authorizationParams,
    }

    const configOptions: PopupConfigOptions = {
      timeoutInSeconds: 120,
      popup,
    }

    await auth0.loginWithPopup(options, configOptions)
  } catch (error) {
    console.error('Error continuing with email:', error)
  } finally {
    if (popup && !popup.closed) {
      popup.close()
    }
  }
}

const isPopupWindowOpen = computed(() => {
  return auth0.isPopupWindowOpen
})

const isProcessingLogin = computed(() => {
  return auth0.isProcessingPopupLogin
})

watch(isPopupWindowOpen, (newVal, oldVal) => {
  if (!newVal && oldVal) {
    emit('update:popup')
  }
})
</script>
