import { defineStore } from "pinia"
import { computed, Ref, ref, set, watch } from "vue"
import { DateTime, SystemZone } from 'luxon'
import { debounce } from 'lodash'
import auth from "./auth"
import { v4 as uuidv4 } from "uuid"
import { RedirectLoginOptions } from "@auth0/auth0-spa-js"
import { useAuth0 } from "@/composables/useAuth0"
import { SalesBotStep } from "@/models/SalesBotStep"
import {
  SalesBotV2Address,
  SalesBotV2Stop,
  SalesBotV2Vehicle,
  SerializedSalesBotV2Stop
} from "@/models/SalesbotV2Quote"
import { EventType, SalesBotRequirementsReq, SpecialRequirement } from "@/models/dto/SalesBotRequirements"
import {
  SalesBotAmenityOption,
  SalesBotVehicleOption,
  VehicleSelectionOptionsRequest
} from "@/models/dto/SalesbotV2VehicleOptions"
import { TripItineraryV2Stop } from "@/models/ItineraryStop"
import {
  EstimateDropoffTimesRequest,
  EstimateDropoffTimesRequestStop,
  EstimateDropoffTimesResponseStop
} from "@/models/dto/SalesbotEstimatedDropoffTimes"
import salesbot from "@/services/salesbot"
import quotes from "@/services/quotes"
import { getPickupDestinationStrings } from "@/utils/string"
import { ChargeTypeId, SalesBotStepKey, SpecialRequirementsTypeKey, TripTypeKey } from "@/utils/enum"
import { buildRedirectLoginOptions } from "@/utils/auth"
import { getTripItineraryStopsFromSalesBot } from "@/utils/tripItinerary"
import { Event, useGoogleAnalytics } from "@/composables/useGoogleAnalytics";
import { Address } from "@/models/dto"

export const useSalesBotStore = defineStore("salesBot", () => {
  const auth0 = useAuth0()
  const analytics = useGoogleAnalytics()
  const DEFAULT_FIRST_STEP_KEY = SalesBotStepKey.TripType
  const BLANK_ADDRESS: SalesBotV2Address = {
    name: "",
    city: "",
    country: "",
    lat: 0,
    lng: 0,
    postalCode: "",
    state: "",
    street1: "",
    title: "",
    addressTypeKeys: [],
    zoneId: ""
  }

  // ------------------ Private Quote Request State (Ref Variables) ------------------
  const _ada = ref(false)
  const _spab = ref(false)
  const _passengerCount = ref<number>(undefined)
  const _tripTypeKey = ref<TripTypeKey>(TripTypeKey.OneWay)
  const _tripEventTypeKey = ref(null)
  const _stops = ref<SalesBotV2Stop[]>([])
  const _vehicles = ref<SalesBotV2Vehicle[]>([])
  const _vehicleNeededEntireTrip = ref(false)
  const _routeName = ref("")
  const _amenitiesKeys = ref<string[]>([])

  const _requirementOptions = ref<Array<SpecialRequirement>>([])
  const _tripEventTypeOptions = ref<Array<EventType>>([])

  const _vehicleOptions = ref<SalesBotVehicleOption[]>([])
  const _vehicleOptionsLoading = ref(false)

  const _amenityOptions = ref<SalesBotAmenityOption[]>([])
  const _amenityOptionsLoading = ref(false)

  const _hasSpecialScenario = ref(false)

  const _estimatedPrice = ref<number | null>(null)

  // ------------------ Getters (Immutable Access) ------------------
  const ada = computed(() => _ada.value)
  const spab = computed(() => _spab.value)
  const passengerCount = computed(() => _passengerCount.value)
  const tripTypeKey = computed(() => _tripTypeKey.value)
  const tripEventTypeKey = computed(() => _tripEventTypeKey.value)
  const stops = computed(() => _stops.value)
  const vehicles = computed(() => _vehicles.value)
  const vehicleNeededEntireTrip = computed(() => _vehicleNeededEntireTrip.value)
  const routeName = computed(() => _routeName.value)
  const amenitiesKeys = computed(() => _amenitiesKeys.value)

  const requirementOptions = computed(() => _requirementOptions.value)
  const tripEventTypeOptions = computed(() => _tripEventTypeOptions.value)

  const vehicleOptions = computed(() => _vehicleOptions.value)
  const vehicleOptionsLoading = computed(() => _vehicleOptionsLoading.value)
  const hasSpecialScenario = computed(() => _hasSpecialScenario.value)

  const amenityOptions = computed(() => _amenityOptions.value)
  const amenityOptionsLoading = computed(() => _amenityOptionsLoading.value)

  // ------------------ Setters (Controlled Mutations) ------------------

  const setAda = (value: boolean) => {
    _ada.value = value
  }
  const setSpab = (value: boolean) => {
    _spab.value = value
  }
  const setPassengerCount = (count: number) => {
    _passengerCount.value = count
  }
  const setTripTypeKey = (key: TripTypeKey) => {
    _tripTypeKey.value = key
  }
  const setTripEventTypeKey = (key: string) => {
    _tripEventTypeKey.value = key
  }
  const setRouteName = (name: string) => {
    _routeName.value = name
  }
  const setAmenityKeys = (amenitiesKeysList: string[]) => {
    _amenitiesKeys.value = [...amenitiesKeysList]
  }

  const toggleAmenity = (amenity: string) => {
    if (_amenitiesKeys.value.includes(amenity)) {
      removeAmenity(amenity)
    } else {
      addAmenity(amenity)
    }
  }

  const addAmenity = (amenity: string) => {
    if (!_amenitiesKeys.value.includes(amenity)) {
      _amenitiesKeys.value.push(amenity)
    }
  }

  const removeAmenity = (amenity: string) => {
    _amenitiesKeys.value = _amenitiesKeys.value.filter((a) => a !== amenity)
  }

  const setVehicles = (
    vehiclesList: { quantity: number, vehicleTypeKey: string }[]
  ) => {
    _vehicles.value = [...vehiclesList]
  }

  const setTripEventTypeOptions = (eventTypes: EventType[]) => {
    _tripEventTypeOptions.value = eventTypes
  }

  const setRequirementOptions = (requirements: SpecialRequirement[]) => {
    _requirementOptions.value = requirements
  }

  const setVehicleNeededEntireTrip = (value: boolean) => {
    _vehicleNeededEntireTrip.value = value
  }

  const setVehicleOptionsLoading = (value: boolean) => {
    _vehicleOptionsLoading.value = value
  }

  const setVehicleOptions = (value: SalesBotVehicleOption[]) => {
    _vehicleOptions.value = value
  }

  const setAmenityOptionsLoading = (value: boolean) => {
    _amenityOptionsLoading.value = value
  }

  const setAmenityOptions = (value: SalesBotAmenityOption[]) => {
    _amenityOptions.value = value
  }

  const setSelectedVehicleOption = (index: number) => {
    const updatedVehicleOptions = _vehicleOptions.value.map((option, i) => ({
      ...option,
      selected: i === index
    }))

    _vehicleOptions.value = updatedVehicleOptions // Replace array to trigger reactivity
    _vehicles.value = updatedVehicleOptions.filter(option => option.selected).flatMap(option => option.vehicles)
  }

  const setHasSpecialScenario = (value: boolean) => {
    _hasSpecialScenario.value = value
  }

  const setEstimatedPrice = (value: number | null) => {
    _estimatedPrice.value = value
  }

  const addBlankStop = (index?: number) => {
    addStop(BLANK_ADDRESS, null, null, index)
  }

  const addStop = (
    address: SalesBotV2Address,
    pickupDatetime: DateTime | null,
    dropoffDatetime: DateTime | null,
    index?: number
  ) => {
    const newStop: SalesBotV2Stop = {
      address: { ...address },
      pickupDatetime,
      dropoffDatetime,
    }

    const updatedStops = [..._stops.value]

    if (index === undefined) {
      updatedStops.push(newStop)
    } else {
      updatedStops.splice(index, 0, newStop)
    }

    _stops.value = updatedStops // Replace array to trigger reactivity
    debouncedGetEstimatedDropoffTimes()
  }

  /**
   * Updates a stop in the _stops array at the specified index.
   *
   * @param {number} index - The index of the stop to update.
   * @param {SalesBotV2Stop} stop - The new stop data to set at the specified index.
   */
  const updateStop = (
    index: number,
    stop: SalesBotV2Stop
  ): void => {
    if (_stops.value[index]) {
      set(_stops.value, index, stop)
    }

    if (isRoundTrip.value) {
      copyAddressesForRoundTrip(index, stop.address)
    }
    debouncedGetEstimatedDropoffTimes()
  }

  const copyAddressesForRoundTrip = (stopIndex: number, address: SalesBotV2Address): void => {
    const isFirstStop = stopIndex === 0
    const isLastStop = stopIndex === _stops.value.length - 1
    if (!isFirstStop && !isLastStop) {
      return
    }

    const updatedStop: SalesBotV2Stop = {
      pickupDatetime: null,
      dropoffDatetime: null,
      address: { ...address }
    }


    const copyToIndex = isFirstStop ? _stops.value.length - 1 : 0
    set(_stops.value, copyToIndex, updatedStop)
    debouncedGetEstimatedDropoffTimes()
  }

  /**
   * Removes a stop from the list of stops at the specified index.
   *
   * @param {number} index - The index of the stop to remove.
   */
  const removeStop = (index: number): void => {
    _stops.value.splice(index, 1)
    _stops.value[_stops.value.length - 1].pickupDatetime = null

    debouncedGetEstimatedDropoffTimes()
  }

  const setStops = (stopsList: SalesBotV2Stop[]) => {
    _stops.value = [...stopsList]
  }

  // ------------------ Refs for Internal Use ------------------
  const id = ref<string | null>(null)
  const currentStepKey = ref<SalesBotStepKey | null>(null)
  const _estimatedDropoffTimes = ref<EstimateDropoffTimesResponseStop[]>([])

  // ------------------ Auth Management -------------------

  const isAuthenticated = computed<boolean>(() => !!auth.isTokenSet)
  const isAuthorized = computed<boolean>(() => !!auth.userId)

  // ------------------ Trip Estimation Functions (for internal use) -------------------
  const getEstimatedDropoffTimes = async (): Promise<any> => {
    let estimatedDropoffTimes: EstimateDropoffTimesResponseStop[]
    const payload = buildEstimatedDropoffTimesRequest()

    try {
      const { data } = await salesbot.estimateDropoffTimes(payload)
      estimatedDropoffTimes = data.stops
    } catch (error) {
      console.warn("Failed to get trip estimations", error)
    }

    if (!estimatedDropoffTimes) {
      const blankStop: EstimateDropoffTimesResponseStop = { dropoffDatetime: null, zoneId: null }
      estimatedDropoffTimes = _stops.value.map((_stop) => blankStop)
    }

    _estimatedDropoffTimes.value = estimatedDropoffTimes
  }

  const debouncedGetEstimatedDropoffTimes = debounce(getEstimatedDropoffTimes, 500)

  const buildEstimatedDropoffTimesRequest = (): EstimateDropoffTimesRequest => {
    const stops: EstimateDropoffTimesRequestStop[] = _stops.value.map(buildEstimatedDropoffTimesStop)
    return { stops }
  }

  const buildEstimatedDropoffTimesStop = (stop: SalesBotV2Stop): EstimateDropoffTimesRequestStop => {
    const lat = stop.address?.lat ?? null
    const lng = stop.address?.lng ?? null
    const zoneId = stop.address?.zoneId ?? null
    const pickupDatetime = toUTCDatetime(stop?.pickupDatetime, zoneId)

    return { pickupDatetime, zoneId, lat, lng }
  }

  /**
   * Converts a given DateTime object to UTC time based on the provided time zone ID.
   *
   * @param {DateTime | null} datetime - The DateTime object to be converted. If null, the function returns null.
   * @param {string | null} zoneId - The time zone ID to be used for conversion. If null, the function returns null.
   * @returns {DateTime | null} - The converted DateTime object in UTC, or null if the input datetime or zoneId is null.
   */
  const toUTCDatetime = (datetime: DateTime | null, zoneId: string | null): DateTime | null => {
    if (!datetime || !zoneId) {
      return null
    }

    const formattedString = datetime.toFormat("yyyy-MM-dd'T'HH:mm:ss")
    const zonedDatetime = DateTime.fromISO(formattedString, { zone: zoneId })
    return zonedDatetime.toUTC()
  }

  // ------------------ Special Requirement Functions (for internal use) -------------------
  const loadSpecialRequirementOptions = async (): Promise<void> => {
    // We intentionally only disable SPAB here since if the itinerary
    // should change in a way that SPAB is not supported, we don't want it
    // to remain selected. Since ADA is always available, we want to leave
    // it (as opposed to disabling anytime the itinerary changes).
    setSpab(false)

    const isValidPayload = validateSpecialRequirementsPayload()
    if (!isValidPayload) {
      return
    }

    try {
      const { data } = await salesbot.determineRequirements(specialRequirementsPayload.value)
      setTripEventTypeOptions(data.events)
      setRequirementOptions(data.specialRequirements)
    } catch (error) {
      console.warn("Failed to get vehicle options", error)
    }
  }

  const specialRequirementsPayload = computed((): SalesBotRequirementsReq => {
    return {
      tripEventTypeKey: _tripEventTypeKey.value,
      stops: _stops.value.map(stop => (
        {
          address: {
            state: stop?.address?.state
          }
        }
      )),
    }
  })

  const validateSpecialRequirementsPayload = (): boolean => {
    if (!itineraryStep.value.isValid) {
      return false
    }

    return true
  }

  const debounceLoadSpecialRequirementOptions = debounce(loadSpecialRequirementOptions, 500)
  watch(specialRequirementsPayload, () => {
    debounceLoadSpecialRequirementOptions()
  })

  // ------------------ Vehicle Option Functions (for internal use) -------------------
  let latestVehicleRequestId = 0
  const loadVehicleOptions = async (): Promise<void> => {
    setVehicleOptionsLoading(true)
    setVehicleOptions([])
    setVehicles([])

    // User must be logged in to view pricing. The API should already return a 403,
    // this check just prevents us from unnecessarily calling it too early.
    if (!isAuthorized.value || !isAuthenticated.value) {
      setVehicleOptionsLoading(false)
      return
    }

    const isValidPayload = validateVehicleOptionsPayload()
    if (!isValidPayload) {
      setVehicleOptionsLoading(false)
      return
    }

    latestVehicleRequestId++
    const requestId = latestVehicleRequestId

    try {
      const { data } = await salesbot.getVehicleSelectionOptions(vehicleOptionsPayload.value)
      if (requestId === latestVehicleRequestId) {
        setHasSpecialScenario(data.hasSpecialScenario)
        const vehicleOptions = data.vehicleOptions.map((option) => {
          return {
            selected: option.recommended,
            recommended: option.recommended,
            amount: option.amount,
            description: option.description,
            maxPassengerCapacity: option.maxPassengerCapacity,
            vehicles: option.vehicles
          }
        })
        setVehicleOptions(vehicleOptions)
        trackViewVehicleTypeOptions()

        // Automatically select the recommended option if there is one
        const selectedOption = vehicleOptions.find(option => option.selected)
        if (selectedOption) {
          const index = vehicleOptions.indexOf(selectedOption)
          setSelectedVehicleOption(index)
        }
        if (selectedOption.amount) {
          setEstimatedPrice(selectedOption.amount)
        }
      }
    } catch (error) {
      console.warn("Failed to get vehicle options", error)
    } finally {
      if (requestId === latestVehicleRequestId) {
        setVehicleOptionsLoading(false)
      }
    }
  }

  const vehicleOptionsPayload = computed((): VehicleSelectionOptionsRequest => {
    return {
      tripTypeKey: _tripTypeKey.value,
      tripEventTypeKey: _tripEventTypeKey.value,
      stops: _stops.value,
      vehicleNeededEntireTrip: _vehicleNeededEntireTrip.value,
      ada: _ada.value,
      spab: _spab.value,
      passengerCount: _passengerCount.value
    }
  })

  const validateVehicleOptionsPayload = (): boolean => {
    if (!itineraryStep.value.isValid || !tripDetailsStep.value.isValid) {
      return false
    }

    return true
  }

  const debouncedLoadVehicleOptions = debounce(loadVehicleOptions, 500)
  watch([vehicleOptionsPayload, isAuthorized], () => {
    debouncedLoadVehicleOptions()
  })

  function trackViewVehicleTypeOptions(): void {
    const isLoggedIn = !!auth.isTokenSet && !!auth.userId
    const hasBookedBefore = auth?.customer?.convertedQuoteCount > 0

    analytics.trackEvent(Event.ViewOptions, {
      isAuth0: auth0.isInitialized,
      hasBookedBefore,
      isLoggedIn,
    })
  }

  // ------------------ Amenity Option Functions (for internal use) -------------------

  const loadAmenityOptions = async (): Promise<void> => {
    setAmenityOptionsLoading(true)
    setAmenityOptions([])
    setAmenityKeys([])

    const isValidPayload = validateAmenityOptionsPayload()
    if (!isValidPayload) {
      setAmenityOptionsLoading(false)
      return
    }

    try {
      const { data } = await salesbot.getAmenitySelectionOptions({
        vehicleTypeKeys: _vehicles.value.map(({ vehicleTypeKey }) => vehicleTypeKey),
        tripEventTypeKey: _tripEventTypeKey.value,
      })
      setAmenityOptions(data.amenityOptions.map((option) => {
        return {
          supportedVehicleTypeKeys: option.supportedVehicleTypeKeys,
          amenityTypeKey: option.amenityTypeKey,
          label: option.label,
          description: option.description,
          price: option.price
        }
      }))
    } catch (error) {
      console.warn("Failed to get vehicle options", error)
    }

    setAmenityOptionsLoading(false)
  }

  const validateAmenityOptionsPayload = (): boolean => {
    if (!itineraryStep.value.isValid || !tripDetailsStep.value.isValid || !vehiclesStep.value.isValid) {
      return false
    }

    return true
  }

  const debouncedLoadAmenityOptions = debounce(loadAmenityOptions, 500)
  watch(vehicles, () => {
    if (vehicles.value.length) {
      debouncedLoadAmenityOptions()
    }
  })

  // ------------------ Quote Create/Edit Logic -------------------
  function buildSaveQuoteRequest(previousQuoteExternalId: string | null) {
    return {
      quote: {
        ada: _ada.value,
        spab: _spab.value,
        passengerCount: _passengerCount.value,
        tripTypeKey: _tripTypeKey.value,
        tripEventTypeKey: _tripEventTypeKey.value,
        stops: _stops.value,
        vehicles: _vehicles.value,
        vehicleNeededEntireTrip: _vehicleNeededEntireTrip.value,
        routeName: _routeName.value,
        amenitiesKeys: _amenitiesKeys.value
      },
      previousQuoteExternalId
    }
  }

  const saveQuote = async (previousQuoteExternalId?: string) => {
    const request = buildSaveQuoteRequest(previousQuoteExternalId)

    const res = await salesbot.saveQuote(request)
    const quoteId = res.data.quoteId

    if (!hasSpecialScenario.value) {
      // Log out the estimated price differential for monitoring
      const checkoutDetail = await quotes.selfServeCheckoutDetail(quoteId)
      const amenityAmount = checkoutDetail.data.charges.find(
        ({ chargeType }) => chargeType.id === ChargeTypeId.Amenities
      )?.amount ?? 0
      const actualPrice = checkoutDetail.data.totalAmount - amenityAmount

      console.info(`${quoteId} price difference: ${actualPrice - _estimatedPrice.value}`)
    }

    return quoteId
  }

  enum StopValidationError {
    MISSING_ADDRESS = 'MISSING_ADDRESS',
    MISSING_PICKUP_DATETIME = 'MISSING_PICKUP_DATETIME',
    PICKUP_IN_PAST = 'PICKUP_IN_PAST',
    PICKUP_BEFORE_PREVIOUS_PICKUPS = 'PICKUP_BEFORE_PREVIOUS_PICKUPS',
    PICKUP_BEFORE_ESTIMATED_DROPOFF = 'PICKUP_BEFORE_ESTIMATED_DROPOFF',
  }

  // ------------------ Validation Logic -------------------
  type StopValidationMap = Record<number, StopValidationMapItem>
  type StopValidationMapItem = { isValid: boolean; errorType: StopValidationError; errorMessage: string }

  const stopValidationMap = computed((): StopValidationMap => {
    const map: StopValidationMap = {}
    if (!_stops.value?.length) {
      return map
    }

    let maxDatetime: DateTime | undefined = toUTCDatetime(_stops.value[0]?.pickupDatetime, _stops.value[0]?.address?.zoneId)

    for (const [index, stop] of _stops.value.entries()) {
      const { address, pickupDatetime } = stop
      const zoneId = address?.zoneId

      const utcPickupDatetime = toUTCDatetime(pickupDatetime, zoneId)

      const validationResult = validateStop(index, pickupDatetime, maxDatetime, zoneId)
      if (validationResult) {
        map[index] = validationResult
      }

      maxDatetime = maxDatetime && utcPickupDatetime
        ? DateTime.max(maxDatetime, utcPickupDatetime)
        : utcPickupDatetime || maxDatetime
    }

    return map
  })

  /**
   * Validates a stop in the itinerary.
   *
   * @param {number} index - The index of the stop in the itinerary.
   * @param {DateTime | null} pickupDatetime - The pickup datetime for the stop.
   * @param {DateTime | undefined} maxDatetime - The maximum datetime allowed for the stop.
   * @param {string} zoneId - The timezone ID for the stop.
   * @returns {StopValidationMapItem | undefined} - An object containing the validation result and error message, or undefined if the stop is valid.
   */
  const validateStop = (index: number, pickupDatetime: DateTime | null, maxDatetime: DateTime | undefined, zoneId: string): StopValidationMapItem | undefined => {
    const isItineraryCurrentStep = currentStep?.value?.key === SalesBotStepKey.Itinerary
    const isFirstStop = index === 0
    const isLastStop = index === _stops.value.length - 1
    const estimatedDropoffDatetime = estimatedDropoffDatetimes.value[index]
    const minPickupDatetime = DateTime.local({ zone: zoneId ?? new SystemZone() })

    const utcPickupDatetime = toUTCDatetime(pickupDatetime, zoneId)

    const isMissingAddress = !zoneId
    const isMissingPickupDatetime = !pickupDatetime
    const isPickupInPast = pickupDatetime <= minPickupDatetime
    const isPickupBeforePreviousPickups = maxDatetime && utcPickupDatetime < maxDatetime
    const isPickupBeforeEstimatedDropoff = estimatedDropoffDatetime && zoneId && utcPickupDatetime < estimatedDropoffDatetime

    // For errors more missing address and pickup times, we will be preventing next until these are completed
    // we don't need to display the error unless the user has proceeded beyond the itinerary step
    // and then went back and removed the address or pickup time.
    // All other errors will be displayed regardless of the current step.

    if (isMissingAddress) {
      const errorMessage = isItineraryCurrentStep ? '' : 'Address is required'
      return { isValid: false, errorType: StopValidationError.MISSING_ADDRESS, errorMessage }
    }

    // Don't need the rest of the validation for the last stop
    // since we only take the address input
    if (isLastStop) {
      return
    }

    if (isMissingPickupDatetime) {
      const errorMessage = isItineraryCurrentStep ? '' : 'Pickup time is required'
      return { isValid: false, errorType: StopValidationError.MISSING_PICKUP_DATETIME, errorMessage }
    }

    if (isPickupInPast) {
      return { isValid: false, errorType: StopValidationError.PICKUP_IN_PAST, errorMessage: 'Pickup must be in the future' }
    }

    if (!isFirstStop && isPickupBeforePreviousPickups) {
      return { isValid: false, errorType: StopValidationError.PICKUP_BEFORE_PREVIOUS_PICKUPS, errorMessage: 'Pickup must be after prior stops' }
    }

    if (isPickupBeforeEstimatedDropoff) {
      const arrivalTimeFormatted = estimatedDropoffDatetime.toFormat('hh:mm a')
      const arrivalDateFormatted = estimatedDropoffDatetime.toFormat('LL/dd/yy')
      return { isValid: false, errorType: StopValidationError.PICKUP_BEFORE_ESTIMATED_DROPOFF, errorMessage: `Pickup must be after the estimated arrival time of ${arrivalTimeFormatted} on ${arrivalDateFormatted}` }
    }
  }

  const areAddressesEqual = (addr1: Address, addr2: Address): boolean => {
    return addr1?.street1 === addr2?.street1 &&
           addr1?.city === addr2?.city &&
           addr1?.state === addr2?.state &&
           addr1?.postalCode === addr2?.postalCode &&
           addr1?.lat === addr2?.lat &&
           addr1?.lng === addr2?.lng
  }

  const isAddressEmpty = (addr: Address): boolean => {
    return !addr?.lat && !addr?.lng
  }

  /**
   * Applies round trip and one way default stop logic.
   *
   * @param {TripTypeKey} oldTripTypeKey - The old trip type key.
   * @param {TripTypeKey} newTripTypeKey - The new trip type key.
   */
  const applyDefaultStopLogic = (oldTripTypeKey: TripTypeKey, newTripTypeKey: TripTypeKey): void => {
    const isRoundTrip = !!oldTripTypeKey && oldTripTypeKey !== TripTypeKey.RoundTrip && newTripTypeKey === TripTypeKey.RoundTrip
    const isOneWay = !!oldTripTypeKey && oldTripTypeKey === TripTypeKey.RoundTrip && newTripTypeKey !== TripTypeKey.RoundTrip
    const isLessThan3Stops = _stops.value.length < 3
    const isMoreThan2Stops = _stops.value.length > 2
    const lastAddressMatchesFirstAddress = areAddressesEqual(_stops.value[_stops.value.length - 1].address, _stops.value[0].address)

    if (isRoundTrip) {
      if (isLessThan3Stops || !lastAddressMatchesFirstAddress) {
        addBlankStop()
      }
      const isLastStopEmpty = isAddressEmpty(_stops.value[_stops.value.length - 1].address)
      if (isLastStopEmpty) {
        _stops.value[_stops.value.length - 1].address = { ..._stops.value[0].address };
      }
    } else if (isOneWay) {
      const isLastStopEmpty = isAddressEmpty(_stops.value[_stops.value.length - 1].address)
      if (isMoreThan2Stops && isLastStopEmpty) {
        removeStop(_stops.value.length - 1);
      }
    }
  }

  // ------------------ Step Definitions ------------------

  const tripTypeStep = ref<SalesBotStep>({
    title: "Trip Type",
    header: "Let's get you a quote",
    subheader: "What kind of trip is this?",
    key: SalesBotStepKey.TripType,
    isActive: false,
    isVisible: () => false,
    isComplete: false,
    isValid: false,
    component: () => import("@/components/SalesBotTripTypeV2.vue"),
    next: () => {
      setStepComplete(SalesBotStepKey.TripType)
      for (const key in stepRefs) {
        const step = stepRefs[key].value
        if (!step.isComplete) {
          setCurrentStep(step.key)  // Go to the first incomplete step
          return
        }
      }
    },
  })

  const itineraryStep = ref<SalesBotStep>({
    title: "Itinerary",
    header: "Where are you headed?",
    subheader: "Add your trip itinerary below.",
    key: SalesBotStepKey.Itinerary,
    isActive: false,
    isVisible: () => true,
    isComplete: false,
    isValid: false,
    component: () => import("@/components/SalesBotItinerary.vue"),
    next: () => {
      setCurrentStep(SalesBotStepKey.TripDetails)
      setStepComplete(SalesBotStepKey.Itinerary)
    },
  })

  const tripDetailsStep = ref<SalesBotStep>({
    title: "Trip Details",
    header: "Where are you headed?",
    subheader: "Add your trip itinerary below.",
    key: SalesBotStepKey.TripDetails,
    isActive: false,
    isVisible: () => itineraryStep.value.isComplete,
    isComplete: false,
    isValid: false,
    component: () => import("@/components/SalesBotTripDetails.vue"),
    next: () => {
      setStepComplete(SalesBotStepKey.TripDetails)
      if (!isAuthenticated.value) {
        setCurrentStep(SalesBotStepKey.Authentication)
        return
      }
      if (!isAuthorized.value) {
        setCurrentStep(SalesBotStepKey.CustomerDetails)
        return
      }
      setCurrentStep(SalesBotStepKey.Vehicles)
    },
  })

  const authenticationStep = ref<SalesBotStep>({
    title: "Login",
    header: "Your bus prices are one step away!",
    subheader: "Create an account or login to view your prices and save this quote for future access.",
    key: SalesBotStepKey.Authentication,
    isActive: false,
    isVisible: () => false,
    isComplete: false,
    isValid: false,
    component: () => import("@/components/SalesBotAuthentication.vue"),
    next: () => {
      setStepComplete(SalesBotStepKey.Authentication)
      if (!isAuthorized.value) {
        setCurrentStep(SalesBotStepKey.CustomerDetails)
        return
      }
      setCurrentStep(SalesBotStepKey.Vehicles)
    },
  })

  const customerDetailsStep = ref<SalesBotStep>({
    title: "Customer Details",
    header: "Your bus prices are one step away!",
    subheader: "Finish setting up your account.",
    key: SalesBotStepKey.CustomerDetails,
    isActive: false,
    isVisible: () => false,
    isComplete: false,
    isValid: false,
    component: () => import("@/components/SalesBotCustomerDetails.vue"),
    next: () => {
      setStepComplete(SalesBotStepKey.CustomerDetails)
      setCurrentStep(SalesBotStepKey.Vehicles)
    },
  })

  const vehiclesStep = ref<SalesBotStep>({
    title: "Vehicles",
    header: "Where are you headed?",
    subheader: "Add your trip itinerary below.",
    key: SalesBotStepKey.Vehicles,
    isActive: false,
    isVisible: () => isAuthorized.value && tripDetailsStep.value.isComplete,
    isComplete: false,
    isValid: false,
    component: () => import("@/components/SalesBotVehicles.vue"),
    next: () => {
      setStepComplete(SalesBotStepKey.Vehicles)
      setCurrentStep(SalesBotStepKey.Amenities)
    },
  })

  const amenitiesStep = ref<SalesBotStep>({
    title: "Amenities",
    header: "Where are you headed?",
    subheader: "Add your trip itinerary below.",
    key: SalesBotStepKey.Amenities,
    isActive: false,
    isVisible: () => vehiclesStep.value.isComplete,
    isComplete: false,
    isValid: true, // There is currently no way to select invalid amenities
    component: () => import("@/components/SalesBotAmenities.vue"),
    next: () => {
      setStepComplete(SalesBotStepKey.Amenities)
    },
  })

  // ------------------ Step Management ------------------

  /**
   * A computed property that returns the current step of the sales bot.
   *
   * This property computes the current step based on the `currentStepKey` value.
   * If `currentStepKey` is not set, it returns `null`.
   * Otherwise, it retrieves the step corresponding to the `currentStepKey` using the `getStepByKey` function.
   * If no step is found for the given key, it returns `null`.
   *
   * @returns {SalesBotStep | null} The current step of the sales bot or `null` if the step key is not set or invalid.
   */
  const currentStep = computed<SalesBotStep | null>(() => {
    if (!currentStepKey.value) {
      return null
    }
    return getStepByKey(currentStepKey.value) ?? null
  })

  /**
   * Sets the current step in the sales bot workflow.
   *
   * @param stepKey - The key of the step to set as the current step.
   */
  const setCurrentStep = (stepKey: SalesBotStepKey) => {
    const step = getStepByKey(stepKey)
    step.isActive = true
    currentStepKey.value = stepKey
  }

  /**
   * Marks the specified step as complete and deactivates it.
   *
   * @param {SalesBotStepKey} stepKey - The key of the step to be marked as complete.
   */
  const setStepComplete = (stepKey: SalesBotStepKey) => {
    const step = getStepByKey(stepKey)
    step.isComplete = true
    step.isActive = false
  }

  /**
   * Sets the validity of a specific step in the sales bot process.
   *
   * @param {SalesBotStepKey} stepKey - The key identifying the step to update.
   * @param {boolean} isValid - The validity status to set for the step.
   */
  const setStepValid = (stepKey: SalesBotStepKey, isValid: boolean) => {
    const step = getStepByKey(stepKey)
    step.isValid = isValid
  }

  /**
   * A mapping of `SalesBotStepKey` to their respective reactive step references.
   *
   * This guarantees that each step in the SalesBot workflow has a corresponding `Ref<SalesBotStep>`,
   * ensuring type safety and preventing missing steps. By defining this mapping explicitly,
   * we enforce that every `SalesBotStepKey` must have a step instance, making the code more maintainable.
   *
   * Benefits of this approach:
   * - **Ensures full coverage**: TypeScript will enforce that all `SalesBotStepKey` values are included.
   * - **Improves reactivity**: Since each value is a `Ref<SalesBotStep>`, changes in the step data will automatically propagate.
   */
  const stepRefs: Record<SalesBotStepKey, Ref<SalesBotStep>> = {
    [SalesBotStepKey.TripType]: tripTypeStep,
    [SalesBotStepKey.Itinerary]: itineraryStep,
    [SalesBotStepKey.TripDetails]: tripDetailsStep,
    [SalesBotStepKey.Authentication]: authenticationStep,
    [SalesBotStepKey.CustomerDetails]: customerDetailsStep,
    [SalesBotStepKey.Vehicles]: vehiclesStep,
    [SalesBotStepKey.Amenities]: amenitiesStep,
  }

  /**
   * Retrieves the current value of a step based on its `SalesBotStepKey`.
   *
   * This function provides a type-safe way to access a specific step from `stepRefs`.
   * Since `stepRefs` is a `Record<SalesBotStepKey, Ref<SalesBotStep>>`, the returned value
   * is unwrapped from its `Ref`, ensuring that consumers receive the actual `SalesBotStep`
   * object rather than a `Ref` instance.
   *
   * @param {SalesBotStepKey} key - The key identifying the step to retrieve.
   * @returns {SalesBotStep} The current value of the requested step.
   *
   * Usage Example:
   * ```ts
   * const tripDetails = getStepByKey(SalesBotStepKey.TripDetails)
   * ```
   */
  const getStepByKey = (key: SalesBotStepKey): SalesBotStep => {
    return stepRefs[key].value
  }

  /**
   * Computes the steps for the sales bot accordion.
   *
   * This computed property returns an array of `SalesBotStep` objects
   * representing the different steps in the sales bot accordion. The steps
   * include:
   * - Itinerary step
   * - Trip details step
   * - Vehicles step
   * - Amenities step
   *
   * @returns {SalesBotStep[]} An array of `SalesBotStep` objects.
   */
  const accordionSteps = computed<SalesBotStep[]>(() => {
    const itinerary = itineraryStep.value
    const tripDetails = tripDetailsStep.value
    const vehicles = vehiclesStep.value
    const amenities = amenitiesStep.value
    return [itinerary, tripDetails, vehicles, amenities]
  })

  /**
   * A computed property that determines whether the "Proceed to Booking" option should be shown.
   *
   * @returns {boolean} - Returns `true` if the current step key is `SalesBotStepKey.Amenities`, otherwise `false`.
   */
  const showProceedToBooking = computed<boolean>(() => {
    const currentStepKey = currentStep?.value?.key

    // We want to skip the amenities step if there is a special scenario
    if (currentStepKey === SalesBotStepKey.Vehicles && hasSpecialScenario.value) {
      return true
    }

    return currentStepKey === SalesBotStepKey.Amenities
  })

  // ------------------ Session Management ------------------

  /**
   * Initializes a session. If a UUID is provided, it attempts to restore the session
   * from local storage. If the restoration fails or no UUID is provided, it creates a new session.
   *
   * @param {string} [uuid] - The optional UUID of the session to restore.
   * @param {string} [quoteExternalId] - The optional quote id of the session to restore.
   * @returns {void}
   */
  const initializeSession = (uuid?: string, quoteExternalId?: string) => {
    if (uuid) {
      try {
        restoreSessionFromLocalStorage(uuid)
        return
      } catch (error) {
        console.warn('Failed to restore session from local storage', error)
      }
    } else if (quoteExternalId) {
      try {
        restoreSessionFromQuoteId(quoteExternalId)
        return
      } catch (error) {
        console.warn('Failed to restore session from trip', error)
      }
    }
    createNewSession()
  }

  /**
   * Creates a new session by resetting the state and initializing the current step
   * to the first step in the process. Generates a new unique identifier for the session.
   */
  const createNewSession = (): void => {
    clearState()
    // We are starting a new session, so we should reset the state
    // Initialize the current step to the first step in the process
    id.value = uuidv4()
    setCurrentStep(DEFAULT_FIRST_STEP_KEY)
    addBlankStop()
    addBlankStop()
  }

  const clearState = (): void => {
    setDefaultStepState()

    setAda(false)
    setSpab(false)
    setPassengerCount(undefined)
    setTripTypeKey(TripTypeKey.OneWay)
    setTripEventTypeKey(null)
    setStops([])
    setVehicles([])
    setVehicleNeededEntireTrip(false)
    setRouteName('')
    setAmenityKeys([])
    setHasSpecialScenario(false)
    setEstimatedPrice(null)
  }

  const setDefaultStepState = (): void => {
    Object.values(stepRefs).forEach((step) => {
      switch(step.value.key) {
        case SalesBotStepKey.TripType:
          step.value.isComplete = true
          step.value.isValid = true
          step.value.isActive = true
          break
        case SalesBotStepKey.Itinerary:
          step.value.isComplete = false
          step.value.isValid = false
          step.value.isActive = false
          break
        case SalesBotStepKey.TripDetails:
          step.value.isComplete = false
          step.value.isValid = false
          step.value.isActive = false
          break
        case SalesBotStepKey.CustomerDetails:
          step.value.isComplete = isAuthorized.value
          step.value.isValid = false
          step.value.isActive = false
          break
        case SalesBotStepKey.Vehicles:
          step.value.isComplete = false
          step.value.isValid = false
          step.value.isActive = false
          break
        case SalesBotStepKey.Amenities:
          step.value.isComplete = false
          step.value.isValid = true
          step.value.isActive = false
          break
        default:
          break
      }
    })
  }

  /**
   * Retores the session state using the quoteId for quote edit flow.
   *
   * This function sets up the session state based on the provided quoteId.
   * When the quote builder flow is initialized, a check is made for an existing
   * trip. If one is found, the session state is restored from the existing trip
   * using this function.
   *
   * @param {string} quoteExternalId - The id used to retrieve the state.
   * @throws {Error} If the quoteId is null.
   */
  const restoreSessionFromQuoteId = async (quoteExternalId: string): Promise<void> => {
    if (!quoteExternalId) {
      throw new Error(`Invalid quoteExternalId provided`)
    }

    const { data: quote } = await salesbot.getQuote(quoteExternalId)

    // Repopulate the state of each step based on the stored data
    Object.values(stepRefs).forEach((step) => {
      step.value.isComplete = false
      step.value.isValid = true
      step.value.isActive = false
    })
    // Setting invalid since option hasn't been selected
    getStepByKey(SalesBotStepKey.Vehicles).isValid = false

    setAda(quote.ada)
    setSpab(quote.spab)
    setPassengerCount(quote.passengerCount)
    setTripTypeKey(quote.tripTypeKey)
    setTripEventTypeKey(quote.tripEventTypeKey)
    setStops(quote.stops.map(deserializeStop))
    setRouteName(quote.routeName)
    setAmenityKeys(quote.amenitiesKeys)
    setVehicles([]) // Empty so the system will recalculate based on changes
    setVehicleNeededEntireTrip(quote.vehicleNeededEntireTrip)

    debouncedGetEstimatedDropoffTimes()

    const customerDetails = getStepByKey(SalesBotStepKey.CustomerDetails)
    customerDetails.isComplete = isAuthorized.value

    setCurrentStep(SalesBotStepKey.Itinerary)
  }

  /**
   * Restores the session state from local storage using the provided storage key.
   *
   * This function retrieves the stored state JSON from local storage, parses it,
   * and repopulates the state of each step based on the stored data. It also sets
   * various state properties and determines the current step based on the stored
   * state and routing logic.
   *
   * @param {string} storageKey - The key used to retrieve the stored state from local storage.
   * @throws {Error} If no stored state is found for the provided key.
   */
  const restoreSessionFromLocalStorage = (storageKey: string): void => {
    const storedStateJSON = localStorage.getItem(storageKey)
    if (!storedStateJSON) {
      throw new Error(`No stored state found for key: ${storageKey}`)
    }

    id.value = storageKey
    const storedState = JSON.parse(storedStateJSON)

    // Repopulate the state of each step based on the stored data
    Object.entries(stepRefs).forEach(([key, step]) => {
      const storedStep = storedState.stepKeyStatuses[key as SalesBotStepKey]
      if (storedStep) {
        step.value.isComplete = storedStep.isComplete
        step.value.isValid = storedStep.isValid
        step.value.isActive = storedStep.isActive
      }
    })
    setAda(storedState.ada)
    setSpab(storedState.spab)
    setPassengerCount(storedState.passengerCount)
    setTripTypeKey(storedState.tripTypeKey)
    setTripEventTypeKey(storedState.tripEventTypeKey)
    setStops(storedState.stops.map(deserializeStop))
    setRouteName(storedState.routeName)
    setAmenityKeys(storedState.amenitiesKeys)
    setVehicles(storedState.vehicles)
    setVehicleNeededEntireTrip(storedState.vehicleNeededEntireTrip)

    debouncedGetEstimatedDropoffTimes()

    const customerDetails = getStepByKey(SalesBotStepKey.CustomerDetails)
    customerDetails.isComplete = isAuthorized.value

    // Determine the current step based on the stored state and our next routing logic
    setCurrentStep(DEFAULT_FIRST_STEP_KEY)
    while (currentStep.value.isComplete) {
      currentStep.value.next()
    }
  }

  /**
   * Saves the current session state to local storage using the session ID as the key.
   *
   * @throws {Error} If no session ID is found.
   *
   * The state includes the following properties:
   * - `stepKeyStatuses`: An object mapping each SalesBot step key to its status (isActive, isComplete, isValid).
   * - `ada`: The ADA (Americans with Disabilities Act) compliance status.
   * - `spab`: The SPAB (School Pupil Activity Bus) compliance status.
   * - `passengerCount`: The number of passengers.
   * - `tripTypeKey`: The key representing the type of trip.
   * - `tripEventTypeKey`: The key representing the type of trip event.
   * - `stops`: The stops in the trip.
   * - `vehicles`: The vehicles involved in the trip.
   * - `vehicleNeededEntireTrip`: Whether the vehicle is needed for the entire trip.
   * - `routeName`: The name of the route.
   * - `amenitiesKeys`: The selected amenity keys.
   */
  const saveSessionToLocalStorage = () => {
    const storageKey = id.value;
    if (!storageKey) {
      throw new Error("No session ID found to save state")
    }

    const stepKeyStatuses: Record<
      SalesBotStepKey,
      { isActive: boolean; isComplete: boolean; isValid: boolean }
    > = {
      [SalesBotStepKey.TripType]: {
        isActive: tripTypeStep.value.isActive,
        isComplete: tripTypeStep.value.isComplete,
        isValid: tripTypeStep.value.isValid,
      },
      [SalesBotStepKey.Itinerary]: {
        isActive: itineraryStep.value.isActive,
        isComplete: itineraryStep.value.isComplete,
        isValid: itineraryStep.value.isValid,
      },
      [SalesBotStepKey.TripDetails]: {
        isActive: tripDetailsStep.value.isActive,
        isComplete: tripDetailsStep.value.isComplete,
        isValid: tripDetailsStep.value.isValid,
      },
      [SalesBotStepKey.Authentication]: {
        isActive: authenticationStep.value.isActive,
        isComplete: authenticationStep.value.isComplete,
        isValid: authenticationStep.value.isValid,
      },
      [SalesBotStepKey.CustomerDetails]: {
        isActive: customerDetailsStep.value.isActive,
        isComplete: customerDetailsStep.value.isComplete,
        isValid: customerDetailsStep.value.isValid,
      },
      [SalesBotStepKey.Vehicles]: {
        isActive: vehiclesStep.value.isActive,
        isComplete: vehiclesStep.value.isComplete,
        isValid: vehiclesStep.value.isValid,
      },
      [SalesBotStepKey.Amenities]: {
        isActive: amenitiesStep.value.isActive,
        isComplete: amenitiesStep.value.isComplete,
        isValid: amenitiesStep.value.isValid,
      },
    }

    const ada = _ada.value
    const spab = _spab.value
    const passengerCount = _passengerCount.value
    const tripTypeKey = _tripTypeKey.value
    const tripEventTypeKey = _tripEventTypeKey.value
    const stops = _stops.value.map(serializeStop)
    const vehicles = _vehicles.value
    const vehicleNeededEntireTrip = _vehicleNeededEntireTrip.value
    const routeName = _routeName.value
    const amenitiesKeys = _amenitiesKeys.value

    const state = {
      stepKeyStatuses,
      ada,
      spab,
      passengerCount,
      tripTypeKey,
      tripEventTypeKey,
      stops,
      vehicles,
      vehicleNeededEntireTrip,
      routeName,
      amenitiesKeys,
    }
    localStorage.setItem(storageKey, JSON.stringify(state))
  }

  /**
   * Redirects the user to the sign-up page with specific query parameters.
   *
   * @remarks
   * This function constructs an application state object with a route name and query parameters,
   * then builds redirect login options and initiates a redirect to the sign-up page using Auth0.
   */
  const redirectToSignUp = (): void => {
    const appState = {
      routeName: "quote-builder",
      query: { uuid: id.value }
    }
    const options: RedirectLoginOptions = buildRedirectLoginOptions(appState)
    auth0.signupWithRedirect(options)
  }

  const serializeStop = (stop: SalesBotV2Stop): SerializedSalesBotV2Stop => {
    return {
      address: stop.address,
      pickupDatetime: stop.pickupDatetime?.toISO() ?? null,
      dropoffDatetime: stop.dropoffDatetime?.toISO() ?? null,
    }
  }

  const deserializeStop = (stop: SerializedSalesBotV2Stop): SalesBotV2Stop => {
    const zoneId = stop.address?.zoneId
    return {
      address: stop.address,
      pickupDatetime: stop.pickupDatetime && zoneId ? DateTime.fromISO(stop.pickupDatetime, { zone: zoneId }): null,
      dropoffDatetime: stop.dropoffDatetime && zoneId ? DateTime.fromISO(stop.dropoffDatetime, { zone: zoneId }) : null,
    }
  }

  // ------------------ Stops Formatted for the Itinerary ------------------
  const isRoundTrip = computed<boolean>(() => _tripTypeKey.value === TripTypeKey.RoundTrip)

  const calculateEstimatedDropoffDatetime = (stopIndex: number): DateTime | null => {
    const estimatedDropoffStop = _estimatedDropoffTimes.value[stopIndex]
    if (!estimatedDropoffStop) {
      return null
    }
    const { dropoffDatetime, zoneId } = estimatedDropoffStop
    if (!dropoffDatetime || !zoneId) {
      return null
    }
    const estimatedDropoffDatetime = DateTime.fromISO(dropoffDatetime, { zone: zoneId })
    return estimatedDropoffDatetime
  }

  /**
   * A computed property that calculates the estimated dropoff datetimes for each stop.
   *
   * This property returns an array of `DateTime` or `null` values, where each element
   * corresponds to the estimated dropoff datetime for a stop in the `_stops` array.
   *
   * The calculation is based on the travel time between stops and the dropoff or pickup
   * datetime of the previous stop. If the stop is the first in the array, the previous
   * stop is considered `null`.
   *
   * @returns {(DateTime | null)[]} An array of estimated dropoff datetimes for each stop.
   */
  const estimatedDropoffDatetimes = computed<(DateTime | null)[]>((): (DateTime | null)[] => {
    return _stops.value.map((_stop, index) => calculateEstimatedDropoffDatetime(index))
  })

  interface ItineraryStop {
    stopTypeLabel: 'Pickup' | 'Dropoff' | 'Stop'
    isLastStop: boolean
    stop: SalesBotV2Stop
    hideAdd: boolean
    hideDelete: boolean
    showKeepOnSite: boolean
    minimumDatetime: DateTime
    arrivalText: string
    errorMessage: string
    isValid: boolean
  }

  const itineraryStops = computed<ItineraryStop[]>(() => {
    const stopCount = _stops.value.length

    const firstStop = _stops.value[0]
    let globalMinimumDatetime = DateTime.local({ zone: firstStop?.address?.zoneId || new SystemZone() })

    return _stops.value.map((stop, index) => {
      const isFirstStop = index === 0
      const isLastStop = index === stopCount - 1
      const isLastStopOfRoundTrip = isLastStop && isRoundTrip.value
      const zoneId = stop.address?.zoneId ?? ''

      globalMinimumDatetime = calculateGlobalMinimumRequiredDatetime(index, zoneId, globalMinimumDatetime)

      const estimatedDropoffDatetime = estimatedDropoffDatetimes.value[index]
      const formattedEstimatedDropoffDate = estimatedDropoffDatetime?.toFormat('cccc, LLLL d')
      const formattedEstimatedDropoffTime = estimatedDropoffDatetime?.toFormat('h:mm a')
      const arrivalText = estimatedDropoffDatetime ? `Arrives on ${formattedEstimatedDropoffDate} at ${formattedEstimatedDropoffTime}` : ''
      const minimumDatetime = estimatedDropoffDatetime ?? globalMinimumDatetime
      const stopValidation = stopValidationMap.value[index]
      const errorMessage = stopValidation?.errorMessage ?? ''
      const isValid = stopValidation?.isValid ?? true

      const itineraryStop: ItineraryStop = {
        stopTypeLabel: isFirstStop ? 'Pickup' : isLastStop ? 'Dropoff' : 'Stop',
        isLastStop,
        stop,
        hideDelete: stopCount <= 2 || isLastStopOfRoundTrip,
        hideAdd: isLastStop,
        showKeepOnSite: index === 1 && stopCount > 2,
        minimumDatetime,
        arrivalText,
        errorMessage,
        isValid
      }

      return itineraryStop
    })
  })

  const calculateGlobalMinimumRequiredDatetime = (stopIndex: number, zoneId: string, currentMinimumDatetime: DateTime): DateTime => {
    const previousPickupDatetime = stopIndex > 0 ? _stops.value[stopIndex - 1].pickupDatetime : null
    const currentDatetimeAtStop = DateTime.now().setZone(zoneId || new SystemZone())
    const estimatedDropoffDatetime = estimatedDropoffDatetimes.value[stopIndex]
    const timesPickupMostBeAfter = [currentDatetimeAtStop, currentMinimumDatetime, estimatedDropoffDatetime, previousPickupDatetime].filter(Boolean)
    const minimumDatetime = DateTime.max(...timesPickupMostBeAfter)
    return minimumDatetime
  }


  // ------------------ Trip Summary Data ------------------
  interface TripSummary {
    name: string
    stops: TripItineraryV2Stop[]
    passengers: number | undefined
    specialRequirements: SpecialRequirement[]
    vehicles: { quantity: number, vehicleTypeKey: string }[]
    amenities: SalesBotAmenityOption[]
    pickupLocation: string
    dropoffLocation: string
    firstPickupDatetime: DateTime | null
    lastDropoffDatetime: DateTime | null
  }

  const tripSummary = computed((): TripSummary => {
    const name = _routeName.value
    const shouldGenerateStops = _stops.value.filter(stop => stop.address?.name || stop.pickupDatetime).length > 0
    const stops = shouldGenerateStops ? getTripItineraryStopsFromSalesBot(_stops.value, estimatedDropoffDatetimes.value, _vehicleNeededEntireTrip.value) : []

    const passengers = _passengerCount.value ? Number(_passengerCount.value) : undefined

    const selectedSpecialRequirements = [_ada.value && SpecialRequirementsTypeKey.ADA, _spab.value && SpecialRequirementsTypeKey.SPAB].filter(Boolean)
    const specialRequirements = [..._requirementOptions.value].filter(({key}) => selectedSpecialRequirements.includes(key))

    const vehicles = _vehicles.value.map(({ quantity, vehicleTypeKey }) => ({ quantity, vehicleTypeKey }))

    const amenities = _amenityOptions.value.filter(({ amenityTypeKey }) => _amenitiesKeys.value.includes(amenityTypeKey))

    const firstAddress = _stops.value[0]?.address
    const lastAddress = _stops.value.filter(({address}) => !!address?.name && !!address).pop()?.address
    const { pickup, dropoff } = getPickupDestinationStrings(firstAddress?.name, firstAddress?.city, firstAddress?.state, lastAddress?.name, lastAddress?.city, lastAddress?.state)
    const pickupLocation = pickup
    const dropoffLocation = dropoff

    const firstPickupDatetime = _stops.value[0]?.pickupDatetime
    const lastDropoffDatetime = estimatedDropoffDatetimes.value[_stops.value.length - 1]

    return { stops, name, passengers, specialRequirements, vehicles, amenities, pickupLocation, dropoffLocation, firstPickupDatetime, lastDropoffDatetime }
  })

  // ------------------ Exports ------------------

  return {
    accordionSteps,
    currentStep,
    getStepByKey,
    setCurrentStep,
    setStepValid,
    applyDefaultStopLogic,
    initializeSession,
    stops,
    addBlankStop,
    updateStop,
    removeStop,
    setTripTypeKey,
    setVehicleNeededEntireTrip,
    requirementOptions,
    tripEventTypeOptions,
    vehicleOptions,
    vehicleOptionsLoading,
    setSelectedVehicleOption,
    setEstimatedPrice,
    amenityOptions,
    amenityOptionsLoading,
    amenitiesKeys,
    toggleAmenity,
    showProceedToBooking,
    tripTypeKey,
    vehicleNeededEntireTrip,
    itineraryStops,
    stopValidationMap,
    passengerCount,
    setPassengerCount,
    ada,
    setAda,
    spab,
    setSpab,
    tripEventTypeKey,
    setTripEventTypeKey,
    routeName,
    setRouteName,
    tripSummary,
    saveQuote,
    hasSpecialScenario
  };
});
