<template>
  <div id="sales-bot-form" class="d-flex flex-column h-full">
    <div class="d-flex align-center text-primary margin-b-4" >
      <CUIcon
        color="primary"
        view-box="0 0 24 24"
        class="margin-r-2 cursor-pointer"
        @click="handleBackButtonClick"
        hide-tooltip
      >
        west
      </CUIcon>
      <span @click="handleBackButtonClick" class="cursor-pointer">
        Go back
      </span>
    </div>

    <h2 id="sales-bot-header" class="font-medium font-28 margin-b-2" :style="{'line-height': 1.2}">
      {{ salesBotStore?.currentStep?.header }}
    </h2>
    <h3 id="sales-bot-subheader" class="font-book font-20 margin-b-6" :style="{'line-height': 1.2}">
      {{ salesBotStore?.currentStep?.subheader }}
    </h3>

    <component
      v-if="isCustomerDetailsStep"
      :is="salesBotStore?.currentStep?.component"
      @update:customer="onCustomerUpdate"
      @form:validate="onValidate(SalesBotStepKey.CustomerDetails, $event)"
    >
      <template #next-button="{ id, loading, click, validate }">
        <v-btn
          :id="id"
          class="w-full padding-y-4 d-flex align-center font-16 margin-b-4"
          color="primary"
          small
          :loading="loading"
          :disabled="!salesBotStore?.currentStep.isValid"
          @click="click"
        >
          Next
          <CUIcon view-box="0 0 24 24" class="w-20 h-20 margin-l-1">
            arrow_right
          </CUIcon>
        </v-btn>
      </template>
    </component>
    <component
      v-else-if="isAuthenticationStep"
      :is="salesBotStore?.currentStep?.component"
      @update:popup="onPopupUpdate"
    />
    <div v-else id="sales-bot-accordion-container" class="d-flex flex-column h-full">
      <!-- Scrollable accordion content -->
      <div class="flex-grow-1" :style="{ paddingBottom: accordionContainerPadding }">
        <div
          v-for="(step, stepIndex) in visibleAccordionSteps"
          :key="`sales-bot-accordion-step-${step.key}-${stepIndex}`"
          :id="`sales-bot-accordion-step-${step.key}`"
        >
          <div
            v-if="!step.isActive || step.isComplete"
            :id="`sales-bot-accordion-step-${step.key}-header`"
            class="d-flex justify-space-between align-center h-48 border-b-1 border-x-0 border-t-0 border-solid border-midnight-gray-300 cursor-pointer"
            @click="step.isActive = !step.isActive"
          >
            <h4 :id="`sales-bot-accordion-step-${step.key}-accordion-title`" class="font-medium font-20 margin-y-0">{{ step.title }}</h4>
            <CUIcon
              :id="`sales-bot-accordion-step-${step.key}-accordion-arrow`"
              view-box="0 0 24 24"
              class="w-24 h-24"
              :class="{ 'active-arrow': step.isActive }"
            >
              keyboard_arrow_down
            </CUIcon>
          </div>

          <div v-show="step.isActive" class="margin-t-4">
            <h4
              v-if="!step.isComplete"
              :id="`sales-bot-accordion-step-${step.key}-active-title`"
              class="font-bold font-24 margin-t-0"
            >
              {{ step.title }}
            </h4>

            <component :is="step.component" @form:validate="onValidate(step.key, $event)">
              <template v-if="step.key === salesBotStore.currentStep?.key" #next-button="{ validate }">
                <v-btn
                  v-if="!salesBotStore?.showProceedToBooking && step.key === salesBotStore?.currentStep?.key"
                  :id="`sales-bot-accordion-step-${step.key}-next-button`"
                  :disabled="!step.isValid"
                  class="w-full padding-y-4 d-flex align-center font-16 margin-b-4"
                  color="primary"
                  small
                  @click="goToNext(validate, step)"
                >
                  Next
                  <CUIcon view-box="0 0 24 24" class="w-20 h-20 margin-l-1">
                    arrow_right
                  </CUIcon>
                </v-btn>
              </template>
            </component>
          </div>
        </div>
      </div>
      <div
        v-if="!smAndDown && salesBotStore?.showProceedToBooking"
        class="bottom-0 z-3"
      >
        <SalesBotToBookingButton
          id="sales-bot-accordion-to-booking-button"
          class="margin-b-4"
          :disabled="!areAllStepsValid"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSalesBotStore } from '@/store/modules/salesBot'
import { computed } from 'vue'
import { SalesBotStep } from '@/models/SalesBotStep'
import { SalesBotStepKey } from '@/utils/enum'
import SalesBotToBookingButton from '@/components/SalesBotToBookingButton.vue'
import { useDisplay } from '@/composables/useDisplay'
import { useAuth0 } from "@/composables/useAuth0";
import { useGoogleAnalytics, Event } from "@/composables/useGoogleAnalytics";
import auth from "@/store/modules/auth";

const salesBotStore = useSalesBotStore()
const { smAndDown } = useDisplay()

// Computed properties
const isCustomerDetailsStep = computed(() => salesBotStore?.currentStep?.key === SalesBotStepKey.CustomerDetails)
const isAuthenticationStep = computed(() => salesBotStore?.currentStep?.key === SalesBotStepKey.Authentication)
const accordionSteps = computed<SalesBotStep[]>(() => salesBotStore?.accordionSteps ?? [])
const visibleAccordionSteps = computed(() => accordionSteps.value.filter(step => step.isVisible()))
const areAllStepsValid = computed(() => accordionSteps.value.every(step => step.isValid))
const accordionContainerPadding = computed(() => {
  if (!smAndDown.value) {
    return '0px'
  }
  return salesBotStore?.showProceedToBooking ? '240px' : '180px'
})
const isAuthenticated = computed(() => !!auth.isTokenSet)

// Event handlers
const onCustomerUpdate = () => salesBotStore?.currentStep?.next()
const onPopupUpdate = () => {
  if (isAuthenticated.value) {
    salesBotStore?.currentStep?.next()
  }
}

const onValidate = (stepKey: SalesBotStepKey, isValid: boolean) => {
  salesBotStore.setStepValid(stepKey, isValid)
}

const goToNext = (validate: () => boolean, step: SalesBotStep) => {
  const isValid = validate()
  step.isValid = isValid
  if (!isValid) {
    return
  }

  switch (step.key) {
    case SalesBotStepKey.Itinerary:
      trackStepSubmission(Event.Itinerary)
      break
    case SalesBotStepKey.TripDetails:
      trackStepSubmission(Event.TripDetails)
      break
    case SalesBotStepKey.Vehicles:
      trackStepSubmission(Event.VehicleType)
      break
    default:
  }

  step.next()
}

function handleBackButtonClick(e: MouseEvent) {
  e.preventDefault();
  const currentStepKey = salesBotStore.currentStep?.key
  if ([SalesBotStepKey.CustomerDetails, SalesBotStepKey.Authentication].includes(currentStepKey)) {
    salesBotStore.setCurrentStep(SalesBotStepKey.TripDetails)
  } else {
    salesBotStore.setCurrentStep(SalesBotStepKey.TripType)
  }
}

// GA4 TRACKING
const analytics = useGoogleAnalytics()
const auth0 = useAuth0()

function trackStepSubmission(event: Event): void {
  const isLoggedIn = !!auth.isTokenSet && !!auth.userId
  const hasBookedBefore = auth?.customer?.convertedQuoteCount > 0

  analytics.trackEvent(event, {
    isAuth0: auth0.isInitialized,
    hasBookedBefore,
    isLoggedIn,
  })
}
</script>

<style scoped lang="scss">
.active-arrow {
  transform: rotate(-180deg);
  transition: transform 0.2s linear;
}
</style>
