import Vue, { VueConstructor } from 'vue'
import {
  Auth0Client,
  Auth0ClientOptions,
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  createAuth0Client,
  PopupLoginOptions,
  PopupConfigOptions,
} from '@auth0/auth0-spa-js'
import { auth0Config } from '@/utils/env'
import Auth0User from '@/models/dto/Auth0User'
import Auth0AppState from '@/models/dto/Auth0AppState'
import auth from '@/store/modules/auth'
import user from '@/store/modules/user'

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = (appState?: any) =>
  window.history.replaceState({}, document.title, window.location.pathname)
let instance: Vue | null = null
/** Returns the current instance of the SDK */
export const getInstance = (): Vue | null => instance
/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}: Auth0ClientOptions & {
  onRedirectCallback?: (appState?: Auth0AppState) => void
  redirectUri?: string
}): Vue => {
  if (instance) {
    return instance
  }
  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        isInitialized: false,
        loading: true,
        isAuthenticated: false,
        user: {} as Auth0User,
        auth0Client: null as Auth0Client | null,
        error: null as Error | null,
        isPopupWindowOpen: false,
        isProcessingPopupLogin: false,
      }
    },
    methods: {
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback(): Promise<Auth0AppState> {
        this.setLoading(true)
        let appState
        try {
          const callbackReponse =
            await this.auth0Client.handleRedirectCallback()
          appState = callbackReponse.appState
          this.setUser(await this.auth0Client?.getUser())
          this.setIsAuthenticated(true)
          this.setError(null)
          onRedirectCallback(appState)
        } catch (e) {
          this.setError(e)
        } finally {
          this.setLoading(false)
        }
        return appState
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o: RedirectLoginOptions): Promise<void> {
        if (!this.auth0Client) {
          throw new Error('Auth0 client is not initialized');
        }
        const authorizationParams = {
          ...o.authorizationParams,
          prompt: 'select_account'
        }
        const options = {
          ...o,
          authorizationParams
        }
        return this.auth0Client.loginWithRedirect(options);
      },
      signupWithRedirect(options?: RedirectLoginOptions): Promise<void> {
        return this.auth0Client?.loginWithRedirect({
          ...options,
          authorizationParams: {
            ...options?.authorizationParams,
            prompt: 'select_account',
            screen_hint: 'signup'
          }
        })
      },
      /** Authenticates the user using a popup window */
      async loginWithPopup(o?: PopupLoginOptions, config?: PopupConfigOptions): Promise<void> {
        if (this.isPopupWindowOpen) {
          return
        }

        const defaultPopupOptions: PopupLoginOptions = {
          authorizationParams: {
            redirect_uri: redirectUri,
            prompt: 'login'
          }
        }

        const defaultPopupConfig: PopupConfigOptions = {
          timeoutInSeconds: 120,
        }

        this.setIsPopupWindowOpen(true)
        try {
          await this.auth0Client!.loginWithPopup(o ?? defaultPopupOptions, config ?? defaultPopupConfig)
          this.setIsProcessingPopupLogin(true)
          const token = await this.getTokenSilently()
          auth.setToken({token, legacy: false })
          await user.fetchRequiredInformation()
          if (user.isExistingCustomer) {
            await auth.jwtLogin()
          }
          this.setUser(await this.auth0Client!.getUser())
          this.setIsAuthenticated(await this.auth0Client!.isAuthenticated())
          this.setError(null)
        } catch (e) {
          this.setError(e)
          console.error(e)
        } finally {
          this.setIsPopupWindowOpen(false)
        }
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o: any) {
        // Specify the correct type instead of `any` if available
        return this.auth0Client?.getIdTokenClaims(o)
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o: GetTokenSilentlyOptions) {
        return this.auth0Client?.getTokenSilently({
          ...o,
          authorizationParams: { ...options.authorizationParams }
        })
      },
      /** Logs the user out and removes their session on the authorization server */
      async logout(redirectUri: string = 'login') {
        const loginUrl = `${window.location.origin}/${redirectUri}`
        await this.auth0Client?.logout({
          logoutParams: { returnTo: loginUrl },
        })
      },
      /** Sets the initialization state */
      setIsInitialized(isInitialized: boolean) {
        this.isInitialized = isInitialized
        if (isInitialized) {
        }
      },
      /** Sets the loading state */
      setLoading(loading: boolean) {
        this.loading = loading
      },
      /** Sets the authentication state */
      setIsAuthenticated(isAuthenticated: boolean) {
        this.isAuthenticated = isAuthenticated
      },
      /** Sets the user */
      setUser(user: any) {
        this.user = user
      },
      /** Sets the Auth0 client */
      setAuth0Client(client: Auth0Client) {
        this.auth0Client = client
      },
      /** Sets the popup window status */
      setIsPopupWindowOpen(isPopupWindowOpen: boolean) {
        this.isPopupWindowOpen = isPopupWindowOpen
      },
      /** Sets the processing login with popup status */
      setIsProcessingPopupLogin(isProcessingPopupLogin: boolean) {
        this.isProcessingPopupLogin = isProcessingPopupLogin
      },
      /** Sets the error state */
      setError(error: Error) {
        this.error = error
      },
      /** Use this method to instantiate the SDK client */
      async init() {
        this.setIsInitialized(false)
        const { audience } = auth0Config()
        // Create a new instance of the SDK client using members of the given options object
        this.auth0Client = await createAuth0Client({
          ...options,
          useRefreshTokens: true,
          cacheLocation: 'localstorage',
          authorizationParams: {
            audience,
            redirect_uri: redirectUri,
          },
        })
        this.setError(null)
        try {
          // If the user is returning to the app after authentication..
          if (
            window.location.search.includes('code=') &&
            window.location.search.includes('state=')
          ) {
            this.setError(null)
          }
        } catch (e) {
          this.setError(e)
        } finally {
          // Initialize our internal authentication state
          this.setIsAuthenticated(await this.auth0Client.isAuthenticated())
          this.setUser(await this.auth0Client.getUser())
          this.setLoading(false)
          this.setIsInitialized(true)
        }
      },
    },
  })
  return instance
}
/** Create a simple Vue plugin to expose the wrapper object throughout the application */
export const Auth0Plugin = {
  install(
    Vue: VueConstructor,
    options: Auth0ClientOptions & {
      onRedirectCallback?: (appState?: any) => void
    }
  ): void {
    Vue.prototype.$auth0 = useAuth0(options)
  },
}
