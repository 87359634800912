<template>
  <div :class="{ 'padding-b-34': smAndDown }">
    <CustomerRequiredDetailsForm single-column @update:customer="emit('update:customer')" @form:validate="emit('form:validate', $event)">
      <template #submit-button="{ id, loading, click, validate }">
        <slot v-bind="{ id, loading, click, validate }" name="next-button" />
      </template>
    </CustomerRequiredDetailsForm>
    <v-btn
      id="required-customer-information-back-to-sign-in-button"
      class="margin-t-3 font-medium w-full"
      color="gray-text"
      plain
      small
      @click="auth.logout"
    >
      Back to Login
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import CustomerRequiredDetailsForm from '@/components/CustomerRequiredDetailsForm.vue'
import auth from '@/store/modules/auth'
import { useDisplay } from '@/composables/useDisplay'

const { smAndDown } = useDisplay()

const emit = defineEmits(['update:customer', 'form:validate'])

</script>
