/**
 * Represents an Auth0 connection for authentication.
 */
export class Auth0Connection {
  /**
   * Google Auth0 connection.
   */
  static readonly Google = new Auth0Connection('google', 'Google', 'Google-OAuth2-Connection', 'google_g_logo')

  /**
   * Apple Auth0 connection.
   */
  static readonly Apple = new Auth0Connection('apple', 'Apple', 'apple', 'apple_logo')

  /**
   * Email Auth0 connection.
   */
  static readonly Email = new Auth0Connection('email', 'Email', 'Username-Password-Authentication', 'email_logo')

  /**
   * Creates an instance of Auth0Connection.
   * @param key - The unique key for the connection.
   * @param label - The display label for the connection.
   * @param connectionName - The name of the connection used by Auth0.
   * @param icon - The icon associated with the connection.
   */
  private constructor(
      public readonly key: string,
      public readonly label: string,
      public readonly connectionName: string,
      public readonly icon: string
  ) {}
}
